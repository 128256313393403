<template>
  <div class="col-md-12">
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <b>Requested Transaction ID: </b> {{ transaction.txid }}<br>
              <b>Requested Transaction Status: </b>
              <a-tag color="#f50" v-if="transaction.requested_transaction_status.includes('ADMIN_PENDING') && !transaction.requested_transaction_status.includes('ADMIN_APPROVED') && !transaction.requested_transaction_status.includes('ADMIN_REJECTED') ">Pending</a-tag>
              <a-tag color="#f50" v-if="transaction.requested_transaction_status.includes('ADMIN_PENDING') && transaction.requested_transaction_status.includes('ADMIN_REJECTED')">Rejected</a-tag>
              <a-tag color="#87d068" v-if="transaction.requested_transaction_status.includes('ADMIN_PENDING') && transaction.requested_transaction_status.includes('ADMIN_APPROVED')">Approved</a-tag><br>
              <b>Expense Month : </b> {{ customRequestedAtDate(transaction.requested_at) }}<br>
            </div>
            <div class="col-sm-6">
              <b>Hub: </b> {{ transaction.hub.name }}<br>
              <b>Requested Transaction Approved at: </b> <span v-if="transaction.requested_transaction_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0">{{ customDate(transaction.requested_transaction_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) }}</span><br>
              <b>Requested Transaction Created at : </b> {{ customDate(transaction.created_at) }}<br>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'status',
  props: ['transaction', 'spinning', 'delayTime'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    customRequestedAtDate(date) {
      return moment(date).format('MMMM YYYY')
    },
  },
}
</script>

<style scoped>

</style>
